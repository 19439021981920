@import (reference) '../../style/global.less';

.benefit {
    & > p {
        color: @alude-gray-7;
        &:extend(.semilarge-alude);
        flex-shrink: 1;
    }

    & > :global(.anticon) {
        color: @alude-red-orange;
        align-self: flex-start;
        &:extend(.large-alude);
    }
}
